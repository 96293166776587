body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#sidebar-menu > ul > li > button {
     color: #8699ad;
     display: block;
     padding: 13px 20px;
     font-size: 13.3px;
     position: relative;
     -webkit-transition: all 0.5s;
     transition: all 0.5s;
}

@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.googleapis.com/css?family=Poppins:400,500,600')
}

body {
  font-family: "Poppins", sans-serif;
}
